
body {
  margin: 0;
  overflow: hidden;
}

.webapp {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: black;
  right: 0;
  top: 0;
}

.infopanel {
  position: absolute;
  width: 512px;
  height: calc(100% - 2px);
  right: 1px;
  top: 0;
  background-color: white;
  border-width: 1px;
  border-color: #444;
  border-style: solid;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

.panelContent {
  width: 100%;
  height: 100%;
  display: block;
}

.show {
  opacity: 1;
  visibility: visible;
}
.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  visibility: hidden;
}

.spinner {
  width: 100%;
  height: 100%;
}

.close-btn {
  align-self: flex-end;
  background-color: white;
  cursor: pointer;
  padding-right: 10px;
}

iframe {
  border-width: 0;
}
